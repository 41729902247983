import * as React from "react";

// future time from where countdown will start decreasing

export const useCountDown = (
  stop = false,
  futureTime = new Date(Date.now() + 5000).getTime()
) => {
  const [time, setTime] = React.useState(futureTime - Date.now());

  // ref to store interval which we can clear out later
  // when stop changes through parent component (component that uses this hook)
  // causing the useEffect callback to trigger again
  const intervalRef = React.useRef<NodeJS.Timer | null>(null);

  React.useEffect(() => {
    //    if (intervalRef.current) {

    //        clearInterval(intervalRef.current);
    //        intervalRef.current = null;
    //        return;
    //    }

    const interval = (intervalRef.current = setInterval(() => {
      setTime(futureTime - Date.now());
    }, 1000));

    return () => clearInterval(interval);
  }, [stop]);

  return getReturnValues(time);
};

const getReturnValues = (countDown: number) => {
  const days = Math.floor(countDown / (1000 * 60 * 60 * 24));
  const hours = Math.floor(
    (countDown % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
  );
  const minutes = Math.floor((countDown % (1000 * 60 * 60)) / (1000 * 60));
  const seconds = Math.floor((countDown % (1000 * 60)) / 1000);

  return [days, hours, minutes, seconds];
};
