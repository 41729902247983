import React, { useState } from "react";
import {
  Box,
  Menu,
  useMediaQuery,
  Flex,
  Text,
  Image,
  ListItem,
  List,
  Container,
} from "@chakra-ui/react";
import Logo from "assets/images/logo.svg";
import mobileLogo from "assets/images/logo.svg";
import headerBg from "assets/images/navbar-border.png";
import { Link } from "react-router-dom";
import { menu } from "utils/const";
import ConnectButton from "component/connectBtn";

export default function Header({
  walletAddress,
  setWalletAddress,
}: {
  walletAddress: string;
  setWalletAddress: any;
}) {
  const intoView = (id: string) => {
    const elem = document.getElementById(id);
    elem?.scrollIntoView({ behavior: "smooth" });
  };
  const [isLargerThan960] = useMediaQuery("(min-width: 960px)");
  return (
    <Box as="header" p={{ md: "40px 4%" }} position="absolute" w="100%" top="0">
      <Container maxW="1280px" p="0">
        <Flex
          bg={{ base: "black", md: `url(${headerBg}) no-repeat top` }}
          bgSize={{ md: "100% 100%" }}
          p={{ base: "20px 25px", md: "25px 6% 25px 6.5%" }}
          align="center"
        >
          <Box>
            <Image src={isLargerThan960 ? Logo : mobileLogo} alt="Logo" />
          </Box>
          {isLargerThan960 && (
            <Flex as="nav" mx="auto" gap="2" align="center">
              {menu.map(({ label, path }) => (
                <Text
                  onClick={() => intoView(path)}
                  as={Link}
                  to={path}
                  key={label}
                  fontSize="18px"
                  fontWeight="400"
                  mr="16px"
                  variant="primary"
                >
                  {label}
                  <sup>
                    {label == "Stake" && (
                      <Box
                        display="inline-block"
                        bg="#FF0000"
                        rounded="50%"
                        w="9px"
                        h="9px"
                      />
                    )}
                  </sup>
                </Text>
              ))}
            </Flex>
          )}
          <ConnectButton
            walletAddress={walletAddress}
            setWalletAddress={setWalletAddress}
          />
          {!isLargerThan960 && <MobileMenu onClick={intoView} />}
        </Flex>
      </Container>
    </Box>
  );
}

function MobileMenu({ onClick }: { onClick: (path: string) => void }) {
  const [isNavOpen, setIsNavOpen] = useState(false);

  function handleNavbar() {
    if (isNavOpen) {
      setIsNavOpen(false);
    } else {
      setIsNavOpen(true);
    }
  }

  return (
    <Box ml="7px">
      <Menu>
        <button
          id="navbar-toggler"
          className={`navbar-toggler ${isNavOpen ? "" : "collapsed"}`}
          type="button"
          onClick={handleNavbar}
        >
          <span></span>
          <span></span>
          <span></span>
        </button>
        <List
          display={isNavOpen ? "block" : "none"}
          position="fixed"
          top={{ base: "78px", md: "120px" }}
          left="0"
          right="0"
          bottom="0"
          spacing="10px"
          p="30px 20px 15px"
          bg="primary.600"
          zIndex="3"
        >
          <ListItem bg="black">
            <Text
              as="a"
              href={"https://escocasino.com/"}
              textAlign="center"
              display="block"
              mb="15px"
              p="5px 0"
              fontSize="31px"
              fontFamily="var(--chakra-fonts-body)"
              color="white"
              target="_blank"
            >
              Esco Casino
            </Text>
          </ListItem>
          {menu.map(({ label, path }) => (
            <ListItem key={label} bg="black">
              <Text
                onClick={() => {
                  setIsNavOpen(false);
                  onClick(path);
                }}
                as="a"
                href={path}
                textAlign="center"
                display="block"
                mb="15px"
                p="5px 0"
                fontSize="31px"
                fontFamily="var(--chakra-fonts-body)"
                color="white"
              >
                {label}
              </Text>
            </ListItem>
          ))}
        </List>
      </Menu>
    </Box>
  );
}
