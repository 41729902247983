import React, { useState } from "react";
import { Button } from "@chakra-ui/react";
import { connectAccount, disconnectWallet } from "utils/bc-helpers";

export default function ConnectButton({
  walletAddress,
  setWalletAddress,
}: {
  walletAddress: string;
  setWalletAddress: any;
}) {
  const [loading, setLoading] = useState(false);
  // const label = isConnected
  //     ? address?.replaceAll(address.slice(5, 38), '****')
  //     : 'Connect Wallet'

  const label = walletAddress
    ? walletAddress?.replaceAll(walletAddress.slice(5, 38), "****")
    : "Connect Wallet";

  async function onOpen() {
    setLoading(true);
    await open();
    setLoading(false);
  }

  function onClick() {
    // if (isConnected) {
    //     disconnect()
    // } else {
    //     onOpen()
    // }

    if (walletAddress) {
      disconnectWallet();
    } else {
      connectAccount(setWalletAddress);
    }
  }

  return (
    <>
      <Button
        isLoading={loading}
        onClick={onClick}
        ml={{ base: "auto", lg: "0" }}
        mr={{ base: "0", lg: "15px" }}
      >
        {label}
      </Button>
      <a href="https://escocasino.com/" target="_blank" rel="noreferrer">
        <Button display={{ base: "none", lg: "block" }}>Esco Casino</Button>
      </a>
    </>
  );
}
