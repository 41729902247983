import React from 'react'
import './App.css'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import Landing from 'views/landing'
import {
    ChakraProvider,
    withDefaultColorScheme,
    createStandaloneToast,
} from '@chakra-ui/react'
import { extendTheme } from '@chakra-ui/react'
import customTheme from 'utils/customTheme'


export const { toast, ToastContainer } = createStandaloneToast()


const theme = extendTheme(
    customTheme,
    withDefaultColorScheme({
        colorScheme: 'primary',
    }),
)

function App() {
    return (
        <ChakraProvider theme={theme}>
            <BrowserRouter>
                <Routes>
                    <Route path="/" element={<Landing />} />
                </Routes>
            </BrowserRouter>

            <ToastContainer />
        </ChakraProvider>
    )
}

export default App
