import { showToast } from "./helper";
import Web3Modal from "web3modal";
import Web3 from "web3";
import WalletConnectProvider from "@walletconnect/web3-provider";
import { STAKING_ADDRESS, ESCO_ADDRESS, EscoABI, StakeABI } from "./const";

const RPC = "https://mainnet.infura.io/v3/5a65431a2f0848db9b6c2283e70f523f";
const chainId = "0x1";
const networkName = "Ethereum";
const nativeCurrencyName = "ETH";
const nativeCurrencySymbol = "ETH";
const decimals = 18;
const scanUrl = "https://etherscan.io/";

export let web3 = new Web3(RPC);

export const addBSCNetwork = async () => {
  try {
    await window.ethereum.request({
      method: "wallet_addEthereumChain",
      params: [
        {
          chainId: chainId,
          rpcUrls: [RPC],
          chainName: networkName,
          nativeCurrency: {
            name: nativeCurrencyName,
            symbol: nativeCurrencySymbol,
            decimals: decimals,
          },
          blockExplorerUrls: [scanUrl],
        },
      ],
    });
  } catch (err) {
    console.log(err);
    throw err;
  }
};

export const connectAccount = async (setState: any) => {
  try {
    const providerOptions = {
      walletconnect: {
        package: WalletConnectProvider,
        options: {
          bridge: "https://bridge.walletconnect.org",
          chainid: 1,
          rpc: {
            1: RPC,
          },
        },
      },
    };
    const web3Modal = new Web3Modal({
      // network: "fantom",
      cacheProvider: true, // optional
      providerOptions, // required
      disableInjectedProvider: false, // optional. For MetaMask / Brave / Opera.
    });

    const provider = await web3Modal.connect();
    web3 = new Web3(provider);
    const chainid = await web3.eth.getChainId();
    if (chainid != 1) {
      await web3Modal.clearCachedProvider();
      window.localStorage.removeItem("walletconnect");
      window.localStorage.removeItem("WEB3_CONNECT_CACHED_PROVIDER");
      showToast(
        "error",
        "Wrong network connected",
        "Please connect with Ethereum Network!"
      );
      await addBSCNetwork();
      return;
    }
    const accounts = await web3.eth.getAccounts();
    console.log(accounts);
    setState(accounts[0]);
    if (accounts[0]) console.log("success", "wallet connected successfully!");
    provider.on("accountsChanged", (accounts: any) => {
      setState(accounts[0]);
    });

    return accounts[0];
  } catch (error) {
    console.log({
      isOpen: false,
      message: "",
    });
    // throw error
  }
};

export const disconnectWallet = async () => {
  try {
    const providerOptions = {
      walletconnect: {
        package: WalletConnectProvider,
        options: {
          bridge: "https://bridge.walletconnect.org",
          chainid: 11155111,
          rpc: {
            11155111: RPC,
          },
        },
      },
    };
    const web3Modal = new Web3Modal({
      // network: "fantom",
      cacheProvider: true, // optional
      providerOptions, // required
      disableInjectedProvider: false, // optional. For MetaMask / Brave / Opera.
    });

    await web3Modal.clearCachedProvider();
  } catch (err) {
    console.log(err);
    throw err;
  }
};

export const getEscoContract = () => {
  return new web3.eth.Contract(EscoABI, ESCO_ADDRESS);
};

export const getStakingContract = () => {
  return new web3.eth.Contract(StakeABI, STAKING_ADDRESS);
};

export const getEscoBalance = async (address: string) => {
  try {
    /* eslint-disable no-debugger */
    const escoContract = getEscoContract();

    const balanceInWei = await escoContract?.methods?.balanceOf(address).call();

    const balanceInEth = parseFloat(web3.utils.fromWei(balanceInWei));

    return balanceInEth;
    /* eslint-disable no-debugger */
  } catch (err: any) {
    throw new Error(err?.message);
  }
};

export const getLaunchTime = async () => {
  try {
    const stakingContract = getStakingContract();
    const launchTime = await stakingContract.methods.LAUNCH_TIME().call();

    return parseInt(launchTime);
  } catch (err) {
    console.log(err);
  }
};

export const getTotalInvestors = async () => {
  try {
    const stakingContract = getStakingContract();
    const totalInvestors = await stakingContract.methods
      .totalInvestors()
      .call();

    return parseInt(totalInvestors);
  } catch (err) {
    console.log(err);
  }
};

export const getStakedAmount = async () => {
  try {
    const stakingContract = getStakingContract();
    const totalStakedAmountInWei = await stakingContract.methods
      .totalStakedAmount()
      .call();

    const totalStakedAmount = web3.utils.fromWei(totalStakedAmountInWei);

    return parseFloat(totalStakedAmount);
  } catch (err) {
    console.log(err);
  }
};

export const getTotalClaimedReward = async () => {
  try {
    const stakingContract = getStakingContract();
    const totalClaimedAmountInWei = await stakingContract.methods
      .totalClaimedReward()
      .call();

    const totalClaimedAmount = web3.utils.fromWei(totalClaimedAmountInWei);

    return parseFloat(totalClaimedAmount);
  } catch (err) {
    console.log(err);
  }
};

export const getRewardPercentage = async () => {
  try {
    const stakingContract = getStakingContract();
    const currentPercentage = await stakingContract.methods
      .currentPercentage()
      .call();
    const currentPercentageInEth = parseFloat(currentPercentage) / 10 ** 16;
    return currentPercentageInEth;
  } catch (err) {
    console.log(err);
  }
};

export const getRewardBalance = async () => {
  try {
    const stakingContract = getStakingContract();
    const rewardBalance = await stakingContract.methods
      .getRewardBalance()
      .call();

    const rewardBalanceInEth = parseFloat(web3.utils.fromWei(rewardBalance));

    return rewardBalanceInEth;
  } catch (err) {
    console.log(err);
  }
};

export const getUserInvestments = async (address: string) => {
  try {
    const stakingContract = getStakingContract();
    const userInvestments = await stakingContract.methods
      .getUserInvestments(address)
      .call();

    let data = await Promise.all(
      userInvestments?.map((item: any) => {
        const prxData = stakingContract?.methods.investments(item).call();

        return prxData;
      })
    );

    const rewardData = await Promise.all(
      userInvestments?.map((item: any) => {
        const prxData = stakingContract?.methods.getReward(item).call();
        return prxData;
      })
    );

    data = data?.map((item, index) => {
      return {
        ...item,
        id: parseInt(userInvestments[index]),
        rewardAmount: rewardData[index],
      };
    });

    return data;
  } catch (err) {
    console.log(err);
  }
};

export const getUserData = async (address: string) => {
  try {
    const [escoBalanceInEth] = await Promise.all([getEscoBalance(address)]);
    return { escoBalanceInEth };
  } catch (err: any) {
    console.log(err);
    throw new Error(err?.message);
  }
};

export const getGenericData = async () => {
  try {
    const [
      totalInvestors,
      totalClaimedReward,
      stakedAmount,
      rewardPercentage,
      rewardBalance,
      launchTime,
    ] = await Promise.all([
      getTotalInvestors(),
      getTotalClaimedReward(),
      getStakedAmount(),
      getRewardPercentage(),
      getRewardBalance(),
      getLaunchTime(),
    ]);

    return {
      totalInvestors,
      totalClaimedReward,
      stakedAmount,
      rewardPercentage,
      rewardBalance,
      launchTime,
    };
  } catch (err: any) {
    console.log(err);
    throw new Error(err?.message);
  }
};

export const stakeAmountTrx = async (amount: number, walletAddress: string) => {
  try {
    const escoContract = getEscoContract();
    const stakingContract = getStakingContract();

    const escoBalanceInEth = await getEscoBalance(walletAddress);

    // if (escoBalanceInEth < amount) {
    //     showToast(
    //         'error',
    //         'Insufficient Esco Balance',
    //         ""
    //     );

    //     return;
    // }

    const allowanceInWei = await escoContract.methods
      .allowance(walletAddress, STAKING_ADDRESS)
      .call();

    const allowanceInEth = parseFloat(web3.utils.fromWei(allowanceInWei));

    const amountInWei = web3.utils.toWei(amount?.toString());

    if (allowanceInEth < amount) {
      const approveTrx = await escoContract.methods
        .approve(STAKING_ADDRESS, amountInWei)
        .send({
          from: walletAddress,
        });
    }

    const stakeTrx = await stakingContract.methods.stakeEsco(amountInWei).send({
      from: walletAddress,
    });

    showToast("success", "Staked Successfully!", "");

    return stakeTrx;
  } catch (err: any) {
    console.log(err);
    showToast("error", "Something went wrong", err?.message);
  }
};

export const unstakeAmount = async (
  investmentId: number,
  walletAddress: string
) => {
  try {
    const stakingContract = getStakingContract();

    const unstakeTrx = await stakingContract.methods
      .unstakeAmount(investmentId)
      .send({
        from: walletAddress,
      });

    return unstakeTrx;
  } catch (err: any) {
    console.log(err);
    showToast("error", "Something went wrong", err?.message);
  }
};

export const claimReward = async (
  investmentId: number,
  walletAddress: string
) => {
  try {
    const stakingContract = getStakingContract();

    const unstakeTrx = await stakingContract.methods
      .claimReward(investmentId)
      .send({
        from: walletAddress,
      });

    return unstakeTrx;
  } catch (err: any) {
    console.log(err);
    showToast("error", "Something went wrong", err?.message);
  }
};
